
import { defineComponent, ref } from 'vue';

export default defineComponent({
  setup() {
    const open = ref(true);

    const onClose = () => {
      open.value = false;
    };

    return {
      open,
      onClose,
    };
  },
});
