<style lang="scss">
.popupbox {
  max-width: 350px;
  position: fixed;
  top: 300px;
  z-index: 10000;
  background: $blue-darker;
  color: #fff;
  transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  display: none !important;
  &:hover {
    background: $blue-light;
  }
  @media (max-width: 500px) {
    max-width: 100%;
    top: 155px;
  }
  .btn-close-popupbox {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 40px;
    height: 40px;
    text-align: center;

    background: $blue-light;
    @media (max-width: 500px) {
      top: 0px;
    }
    cursor: pointer;
    &:hover {
      transition: all 200ms ease;
      background: $red;
    }
    .icon {
      display: inline-block;
      position: relative;
      top: -8px;

      line-height: 1;
      height: 10px;
      font-size: 40px;
    }
  }
  .highlight-box-inner {
    margin: 30px 55px 30px 35px;
    font-size: 18px;
    position: relative;

    span {
      display: block;
      margin-bottom: 5px;
    }
    .txt {
      display: inline-block;
      font-size: 20px;
    }
    .txt-small {
      margin-top: 10px;
      font-size: 16px;
    }
  }
}
.popupbox-show {
  right: 0;
}
.popupbox-hide {
  right: -300%;
}
</style>

<template>
  <div class="popupbox" :class="open ? 'popupbox-show' : 'popupbox-hide'">
    <div class="btn-close-popupbox" @click="onClose"><span class="icon">&times;</span></div>
    <div class="highlight-box-inner">
      <span>Zum Start der neuen Website</span>
      <div class="txt"><strong>20 % mehr Ad Impressions für Ihre Displaywerbung und Sponsored Story gewinnen!</strong></div>
      <div class="txt-small">Details hier</div>
      <a href="https://www.kleinezeitung.at/advertorials/6125965" class="stretched-link" target="_blank"></a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  setup() {
    const open = ref(true);

    const onClose = () => {
      open.value = false;
    };

    return {
      open,
      onClose,
    };
  },
});
</script>
